import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_customerSelect = _resolveComponent("customerSelect")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.show) = $event)),
    title: "需求分配",
    onOk: _ctx.handleOk,
    destroyOnClose: true,
    afterClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formStateRef",
        model: _ctx.formState,
        "label-col": _ctx.labelCol,
        "wrapper-col": _ctx.wrapperCol
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "需求产品名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formStateProp.demandProduct,
                placeholder: "需求产品名称",
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "应用设备名称" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formStateProp.deviceName,
                type: "number",
                placeholder: "应用设备名称",
                disabled: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "客户名称",
            required: ""
          }, {
            default: _withCtx(() => [
              (!_ctx.isShowSelectChange)
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowSelectChange = true)),
                    value: _ctx.formState.companyName || _ctx.name,
                    placeholder: "客户名称",
                    readonly: ""
                  }, null, 8, ["value"]))
                : (_openBlock(), _createBlock(_component_customerSelect, {
                    key: 1,
                    onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowSelectChange = false)),
                    onSelectKey: _ctx.selectKey,
                    restrict: 1
                  }, null, 8, ["onSelectKey"]))
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "产品经理",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.formState.productManager,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.productManager) = $event)),
                "show-search": "",
                placeholder: "产品经理",
                "default-active-first-option": false,
                "show-arrow": false,
                "filter-option": false,
                "not-found-content": null,
                options: _ctx.productOptionList,
                onChange: _ctx.onChange,
                onSearch: _ctx.handleChange
              }, null, 8, ["value", "options", "onChange", "onSearch"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "产品部门",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                value: _ctx.formState.productDept,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.productDept) = $event)),
                placeholder: "产品部门",
                readonly: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "优先级",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                ref: "select",
                value: _ctx.formState.priority,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.priority) = $event))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionList, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.value,
                      value: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "label-col", "wrapper-col"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "afterClose"]))
}