
import { defineComponent, inject, reactive, ref } from 'vue'
import demandApi from '@/axios/api/demand'
import Data from '@/model/common/Data'
import { message } from 'ant-design-vue'
import customerSelect from '@/components/customerSelect/index.vue'
export default defineComponent({
  components: {
    customerSelect,
  },
  props: {
    status: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    formStateProp: {
      type: Object,
      default: () => {
        return {
          demandProduct: '',
          deviceName: '',
        }
      },
    },
  },
  emits: ['onSuccess'],
  setup(props, { emit }) {
    const show = ref(inject('show'))
    const isShowSelectChange = ref(false)
    const formStateRef = ref()
    const productOptionList = ref([] as Data[])
    const formState = reactive({
      productManager: '',
      productManagerId: '',
      productDept: '',
      productDeptNo: '',
      priority: 0,
      companyName: '',
    })
    const close = () => {
      if (formStateRef.value) formStateRef.value.resetFields()
      Object.assign(formState, {
        existingBrands: '',
        targetPrice: '',
        productManager: '',
        productManagerId: '',
        productDept: '',
        productDeptNo: '',
        priority: 0,
        companyName: '',
      })
      productOptionList.value = []
    }
    const handleOk = () => {
      console.log('确认')
      // if (formState.targetPrice !== '' && formState.targetPrice.slice(0, 1) === '-') {
      //   message.warning('价格不能为负数')
      //   return
      // }
      if (!formState.companyName) {
        formState.companyName = props.name
      }
      demandApi
        .DemandDistribute(Object.assign({ id: props.id, status: props.status }, formState))
        .then(() => {
          message.success('成功')
          show.value = false
          close()
          emit('onSuccess')
        })
        .catch((err) => {
          message.error(err)
        })
    }
    const optionList = [
      { label: '重要紧急', value: 0 },
      { label: '重要不紧急', value: 1 },
      { label: '紧急不重要', value: 2 },
      { label: '不重要不紧急', value: 3 },
    ]
    const handleChange = (val: string) => {
      if (val !== '') {
        productOptionList.value = []
        demandApi
          .ListDeptAndManager({
            data: {
              empname: val,
            },
            noLoading: true,
          })
          .then((res) => {
            if (res.data) {
              productOptionList.value = res.data.map((item) => {
                return {
                  label: item.empName + '-' + item.dptName,
                  value: item.empId + '//' + item.dptNo,
                }
              })
            }
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (value: string, option: any) => {
      console.log(option, option.value.split('//')[0])
      formState.productManager = option.label.split('-')[0]
      formState.productDept = option.label.split('-')[1]
      formState.productManagerId = option.value.split('//')[0]
      formState.productDeptNo = option.value.split('//')[1]
      console.log(formState)
    }
    const selectKey = (val: Data) => {
      formState.companyName = val.label as string
      isShowSelectChange.value = !isShowSelectChange.value
    }
    return {
      show,
      handleOk,
      formState,
      optionList,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      handleChange,
      productOptionList,
      onChange,
      formStateRef,
      isShowSelectChange,
      selectKey,
      close,
    }
  },
})
